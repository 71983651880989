<template>
  <v-dialog
    v-model='dialog'
    width='500'
  >
    <v-card>
      <v-form
        ref='passwordForm'
        class='form-container'
        lazy-validation
      >
        <v-card-title>
          <span class='text-h5'>{{$t('ACTION_CONFIRM')}}</span>
        </v-card-title>
        <v-card-subtitle>
          {{$t('ACTION_CONFIRM_SUBTITLE')}}
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols='12'>
                <v-text-field
                  v-model='password'
                  :rules='passwordRules'
                  label='Senha'
                  type='password'
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class='pt-0'>
          <v-spacer />
          <v-btn
            color='error'
            class='mr-4'
            @click.native='cancel'
          >
            {{$t('CANCEL')}}
          </v-btn>
          <v-btn
            color='success'
            class='mr-4'
            @click.native='verifyUser'
          >
            {{$t('CONFIRM')}}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex';
  import axios from 'axios';

  export default {
    name: 'PasswordRequired',
    data: function () {
      return {
        dialog: true,
        password: '',
        passwordRules: [
          (v) => !!v || 'Senha é obrigatória',
        ],
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      getUserName: function () {
        if (!this.getUser) {
          return undefined;
        }
        return this.getUser.account.name;
      },
      getUserEmail: function () {
        if (!this.getUser) {
          return undefined;
        }

        return this.getUser.account.email;
      },
    },
    methods: {
      cancel: function () {
        this.$emit('password-cancel');
      },
      verifyUser: async function () {
        if (!this.$refs.passwordForm.validate()) {
          return;
        }

        try {
          await axios({
            url: '/account-sign-in',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            data: {
              login: this.getUserEmail,
              password: this.password,
            },
            withCredentials: true,
          });
          this.$emit('password-success');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
